import React from 'react'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

const StyledContainer = styled(Container)`
  ${({theme}) => `
    background: #fff;
    padding-top: 54px;
    min-height: 100vh;
  `}
`

export default function Deletion(props) {


	return (
		<StyledContainer fixed>
			<ol className="">
				<li>
					<Typography>
						Sekiranya anda ingin semua data-data berkaitan anda dipadam di Bizsaya, anda boleh menggunakan 2 cara.
					</Typography>
				</li>
				<li>
					<Typography>
						Anda boleh klik Batalkan akaun pada modul Akaun untuk memadam akaun anda
					</Typography>
				</li>
				<li>
					<Typography>
						Anda juga boleh maklumkan pada admin dengan <a href={'https://ws.bizsaya.com/0172631883?text=batal'}>whatsapp admin</a> atau emel ke info@bizsaya.com
					</Typography>
				</li>
				<li>
					<Typography>
						Semua data berkenaan anda akan dipadam kecuali ID apps facebook Bizsaya anda sahaja. Ini bagi mengelakkan masalah "trial reuse"
					</Typography>
				</li>
				<li>
					<Typography>
						Akaun yang dibatalkan boleh digunakan semula daripada asas. Semua data-data yg ada sebelum ini tidak boleh di-dapatkan semula selepas anda batalkan akaun.
					</Typography>
				</li>
			</ol>
		</StyledContainer>
	)
}
