import React, {forwardRef} from 'react'
import styled from 'styled-components'

import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'

const StyledDialog = styled(Dialog)`
  ${({theme}) => `
    a {
      text-decoration: none;
    }   
    
    .content {
      text-align: justify;
      color: rgba(0, 0, 0, 1);
      
      strong {
        font-size: 1.2rem;
      }
      
      .highlight {
        font-weight: bold;
      }
    } 
  `}
`

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})

export default function ExpiredDialog(props) {

  const {detail, onClose} = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledDialog
      open={true}
      fullScreen={isMobile}
      TransitionComponent={Transition}
      keepMounted
      disableBackdropClick
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title-expired"
      aria-describedby="alert-dialog-slide-description-expired"
    >
      <DialogTitle id="alert-dialog-slide-title-expired">Akaun tamat tempoh</DialogTitle>
      <DialogContent>
        <DialogContentText className={'content'} id="alert-dialog-slide-description-expired">
          Akaun anda <b><u>{detail.name}</u></b> telah tamat tempoh aktif. Sila buat pembayaran ke akaun yang berikut untuk meneruskan penggunaan.
          Jumlah pembayaran adalah berdasarkan jumlah Facebook Page dan/atau jumlah Instagram yang anda tetapkan.
          Maksimum jumlah bulanan yang anda boleh langgan adalah 12 bulan.
          <br/>
          <br/>
          <span className={'highlight'}>1 FB Page & unlimited Instagram - RM15 sebulan</span>
          <br/>
          <span className={'highlight'}>1 FB Page & unlimited Instagram - RM180 setahun + Percuma 15 hari</span>
          <br/>
          <br/>
          <strong>
            Maybank <br/>
            162357375205 - Edi Abdul Rahman
          </strong>
          <br/>
          <br/>
          Setelah anda membuat pembayaran, Sila whatsapp ke nombor berikut untuk proses kemaskini akaun anda
          semula. <a href={`https://api.whatsapp.com/send?phone=60172631883&text=Hi%20admin%20Bisaya,%20Berikut%20adalah%20bukti%20pembayaran%20saya.%20Nama%20akaun:%20${detail.name}`} target={'_blank'} rel={'noreferrer'}>
          Whatsapp admin bizsaya di 0172631883
          </a>.
          <br/>
          <br/>
          Terima kasih kerana menjadikan Bizsaya sebagai teman automasi laman sosial perniagaan anda
          <br/>
          <br/>
          <Typography variant={'caption'}>
            *Anda telah mengaktifkan {detail.totalPages} Facebook Pages di Bizsaya. Oleh itu bayaran bulanan adalah RM{detail.totalPages * 15}
          </Typography>
          <br/>
          <Typography variant={'caption'}>
            *Pembayaran bizsaya adalah berdasarkan jumlah Facebook Pages yang anda aktifkan di Bizsaya. Tiada plan lain.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth={isMobile}
          onClick={onClose}
          variant={'outlined'}
        >
          Ok
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}
