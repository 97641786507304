import React from 'react'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

const StyledContainer = styled(Container)`
  ${({theme}) => `
    background: #fff;
    padding-top: 54px;
    min-height: 100vh;
    
  `}
`

export default function Policy(props) {

  return (
    <StyledContainer fixed>
      <Typography variant={'h3'}>
        Privasi Polisi [Bahasa Melayu]
      </Typography>
      <ol className="">
        <li>
          <Typography>
            Kami akan menyediakan aplikasi Bizsaya as-it-is. Akan tetapi, kami akan mencuba untuk memberikan servis
            yang terbaik buat automasi Facebook Page anda.
          </Typography>
        </li>
        <li>
          <Typography>
            Semua data dan maklumat-maklumat yang diterima/diberi oleh anda tidak akan diberikan ke pihak ke-tiga melainkan sekiranya
            terdapat peruntukan undang-undang yang membolehkan data dan maklumat-maklumat diakses oleh pihak tertentu
          </Typography>
        </li>
        <li>
          <Typography>
            Bizsaya menggunakan beberapa kebenaran daripada Akaun Facebook anda. Kebenaran yang diperlukan bagi akaun Facebook adalah seperti berikut.
            <br/>
            <ul>
              <li>pages_read_user_content</li>
              <li>pages_manage_posts</li>
              <li>pages_show_list</li>
              <li>pages_manage_metadata</li>
              <li>pages_manage_engagement</li>
              <li>pages_messaging</li>
              <li>pages_read_engagement</li>
              <li>instagram_manage_comments</li>
              <li>instagram_basic</li>
            </ul>
          </Typography>
        </li>
        <li>
          <Typography>
            Data yang diberikan oleh anda akan digunakan hanya untuk menerima notifikasi komen daripada page anda dan membalas komen tersebut sekiranya sama dengan tetapan kata kunci yang anda telah tetapkan pada tetapan auto reply anda
          </Typography>
        </li>
        <li>
          <Typography>
            Selain itu juga, Bizsaya akan menggunakan kebenaran tersebut untuk menghantar notifikasi nombor telefon kepada anda sekiranya terdapat nombor telefon didalam komen yang diberikan
          </Typography>
        </li>
        <li>
          <Typography>
            Sekiranya anda ingin semua data-data berkaitan anda dipadam di Bizsaya, anda boleh menggunakan 2 cara.
          </Typography>
        </li>
        <li>
          <Typography>
            1. Anda boleh klik Batalkan akaun pada modul Akaun untuk memadam akaun anda
          </Typography>
        </li>
        <li>
          <Typography>
            2. Anda juga boleh maklumkan pada kami dengan <a href={'https://ws.bizsaya.com/0172631883?text=batal'}>whatsapp kami</a> atau emel ke info@bizsaya.com
          </Typography>
        </li>
        <li>
          <Typography>
            Semua data berkenaan anda akan dipadam kecuali ID apps facebook Bizsaya anda sahaja. Ini bagi mengelakkan masalah "trial reuse" di aplikasi Bizsaya
          </Typography>
        </li>
        <li>
          <Typography>
            Akaun yang dibatalkan boleh digunakan semula ke bizsaya. Tetapi semua data-data yg ada sebelum ini tidak boleh di-dapatkan semula selepas anda batalkan akaun.
          </Typography>
        </li>
      </ol>
      <hr/>
      <Typography variant={'h3'}>Privacy Policy [English]</Typography>
      <ol className="">
        <li>
          <Typography>
            We will provide the application as-it-is. But we will try in our best to provide the best services for to automate your Facebook Page.
          </Typography>
        </li>
        <li>
          <Typography>
            All the data and information that you provide/allow to use will not be provided to third-party unless a clear legal provision that
            allow the data and information to be access by certain parties.
          </Typography>
        </li>
        <li>
          <Typography>
            Bizsaya will request a few permission from your Facebook account. Permission that are required is as below.
            <br/>
            <ul>
              <li>pages_read_user_content</li>
              <li>pages_manage_posts</li>
              <li>pages_show_list</li>
              <li>pages_manage_metadata</li>
              <li>pages_manage_engagement</li>
              <li>pages_messaging</li>
              <li>pages_read_engagement</li>
              <li>instagram_manage_comments</li>
              <li>instagram_basic</li>
            </ul>
          </Typography>
        </li>
        <li>
          <Typography>
            Data that you provided to bizsaya will only be use to received the comment notification from your page and replying the comment if it's match to the keyword that you define in your auto reply settings.
          </Typography>
        </li>
        <li>
          <Typography>
            Other than that, Bizsaya will also use the data that you provide to send phone number notification to you if bizsaya detecting the comment have a phone number
          </Typography>
        </li>
        <li>
          <Typography>
            If you want to opt-out and request the data to be deleted. There is 2 way to do that
          </Typography>
        </li>
        <li>
          <Typography>
            1. You can click on "Batalkan akaun" at module account to delete your account
          </Typography>
        </li>
        <li>
          <Typography>
            2. You also can inform us by <a href={'https://ws.bizsaya.com/0172631883?text=batal'}>whatsapp us</a> or email to info@bizsaya.com
          </Typography>
        </li>
        <li>
          <Typography>
            All the data will be deleted except your facebook ID apps Bizsaya only. This data cannot be deleted to prevent "trial reuse" in our application
          </Typography>
        </li>
        <li>
          <Typography>
            Account that cancel by you can be use back to resubscribe to our application. But all the previous data will not be available to be restore after cancellation
          </Typography>
        </li>
      </ol>
    </StyledContainer>
  )
}
