import React from 'react'
import styled from 'styled-components'
import {ThemeProvider} from 'styled-components'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
  StylesProvider
} from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline'
import NoSsr from '@material-ui/core/NoSsr'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import ElevationScroll from './elavation-scroll'
import Home from './home'
import Terms from './terms'
import Policy from './policy'
import Deletion from './deletion'

const customTheme = createMuiTheme()

const StyledDiv = styled.div`
  .appbar {
    background: rgba(255, 255, 255, 0.35);
    border-bottom: 1px solid rgba(0,0,0, 0.17);

    h6 {
      color: rgba(0, 0, 0, 0.87);
    }
  }
`

export default function App(props) {
  return (
    <StyledDiv>
      <Router>
        <NoSsr>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={customTheme}>
              <ThemeProvider theme={customTheme}>
                <ElevationScroll {...props}>
                  <AppBar position="static" position='fixed' className={'appbar'}>
                    <Container>
                      <Toolbar variant="dense">
                        <IconButton edge="start" className={''} color="inherit" aria-label="menu" onClick={() => window.location.href = '/'}>
                          <img src='https://static.bizsaya.com/file/bizsaya-public/logo_app.png' alt='Logo Bizsaya'/>
                        </IconButton>
                        <Typography variant="h6" className={'title'}>
                          Bizsaya
                        </Typography>
                      </Toolbar>
                    </Container>
                  </AppBar>
                </ElevationScroll>
                <Switch>
                  <Route path='/terms' exact component={Terms} />
                  <Route path='/policy' exact component={Policy} />
                  <Route path='/deletion' exact component={Deletion} />
                  <Route component={Home} />
                </Switch>
              </ThemeProvider>
            </MuiThemeProvider>
          </StylesProvider>
          <CssBaseline/>
        </NoSsr>
      </Router>
    </StyledDiv>
  )
}
